<script setup lang="ts">
    import { type HTMLAttributes, computed } from 'vue';
    import { Label, type LabelProps } from 'radix-vue';
    import { cn } from '@/utils/ui';

    const props = defineProps<
        LabelProps & { class?: HTMLAttributes['class']; required?: boolean }
    >();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });
</script>

<template>
    <Label
        v-bind="delegatedProps"
        :class="
            cn(
                'leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                props.class,
            )
        ">
        <slot /><span v-if="props.required" class="ml-1 text-red-400">*</span>
    </Label>
</template>
